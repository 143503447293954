<!-- eslint-disable vue/no-v-html -->
<template>
	<div :class="containerClass">
		<div class="form-group form-selector">
			<label
				:for="id"
				class="label">{{ title }}
				<span
					v-if="required"
					class="text-danger">*</span>
			</label>
			<multiselect
				:id="id"
				v-model="selectedItem"
				:options="options"
				:disabled="disabled || options.length === 0"
				:placeholder="placeholder"
				:reset-after="resetAfter"
				:close-on-select="closeOnSelect"
				label="text"
				:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
				:select-label="selectLabel"
				:deselect-label="deselectLabel"
				:selected-label="selectedLabel"
				track-by="value">
				<template #option="{option}">
					<span
						style="white-space: pre-line;"
						v-html="option.text" />
				</template>
				<template
					v-slot:singleLabel="{ option }">
					<span v-html="option.text" />
				</template>
			</multiselect>
		</div>
		<slot />
	</div>
</template>
<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';

export default {
	name: 'ItemSelect',
	components: { Multiselect },
	props: {
		id: {
			type: String,
			default: '',
		},
		options: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		resetAfter: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		closeOnSelect: {
			type: Boolean,
			default: true,
		},
		containerClass: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		initialValue: {
			type: [String, Number],
			default: '',
		},
		selectLabel: {
			type: String,
			default: 'Press enter to select',
		},
		deselectLabel: {
			type: String,
			default: 'Press enter to remove',
		},
		selectedLabel: {
			type: String,
			default: 'Selected',
		},
	},
	data() {
		return {
			selectedItem: null,
		};
	},
	watch: {
		options() {
			this.selectedItem = null;
			this.updateSelectedItem();
		},
		selectedItem() {
			this.updateSelectedItem();
		},
		initialValue: {
			immediate: true,
			handler(initialValue) {
				if (initialValue && this.options.length) {
					this.selectedItem = this.options.find((item) => item.value === initialValue);
				}
			},
		},
	},
	mounted() {
		this.$watch(() => this.$attrs['selected-item'], () => {
			this.selectedItem = this.$attrs['selected-item'];
		}, { immediate: true });
	},
	methods: {
		updateSelectedItem() {
			this.$emit('update:selectedItem', this.selectedItem);
		},
	},
};
</script>
